<template>
  <div v-if="raw">
    <el-popover
      ref="popover"
      v-model="visible"
      placement="left"
      offset="10"
      trigger="hover">
      <div class="price-audit">
        <div class="list is-narrow is-small has-shadow-bottom has-text-weight-semibold">
          <div
            class="list-action"
            style="width: 160px;">
            {{ $t('components.PriceAudit.key') }}
          </div>
          <div
            class="list-action"
            style="width: 100px;">
            {{ $t('components.PriceAudit.amount') }}
          </div>
          <div
            class="list-action"
            style="width: 80px;">
            {{ $t('components.PriceAudit.ccy') }}
          </div>
          <div class="list-content">
            {{ $t('components.PriceAudit.description') }}
          </div>
        </div>
        <div
          v-for="(it,index) in raw"
          :key="index"
          class="list is-narrow is-small has-shadow-bottom">
          <div
            class="list-action"
            style="width: 160px;">
            {{ it.key }}
          </div>
          <div
            class="list-action has-text-dark"
            style="width: 100px;">
            {{ it.val }}
          </div>
          <div
            class="list-action has-text-dark"
            style="width: 80px;">
            {{ it.ccy }}
          </div>
          <div class="list-content has-text-left">
            {{ it.des }}
          </div>
        </div>
      </div>
      <!-- <a v-if="!useIcon" class="is-size-7" slot="reference">{{ content }}</a> -->
      <a slot="reference">
        <i
          v-if="useIcon"
          class="is-size-8 el-icon-info has-text-secondary" />
        <span
          v-else
          class="is-size-7">{{ content }}</span>
      </a>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'PriceAudit',
  props: {
    raw: {
      type: Array,
      required: true
    },
    content: {
      type: String,
      default: 'Why?'
    },
    useIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .price-audit {
    font-size: 12px;
    min-width: 310px;
    max-width: 550px;
  }
  .list-action {
    flex: none;
  }
  .list-content {
    word-break: break-all;
  }
</style>
