export function loadImage (imgUrl) {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = function () {
      reject(new Error('can\'t loading image'))
    }
    img.src = imgUrl
  })
}

export async function findCanLoadedImage (images) {
  for (let i = 0; i < images.length; i++) {
    try {
      await loadImage(images[i].url)
      return images[i]
    } catch (e) {}
  }
  return null
}
