<template>
  <g>
    <path
      d="M2 1.28125C1.44772 1.28125 1 1.72897 1 2.28125V21.2652C1 21.6766 1.25203 22.0461 1.63512 22.1962C2.01821 22.3464 2.45416 22.2465 2.73373 21.9446L8.07654 16.1749L13.2556 21.949C13.5323 22.2574 13.9705 22.3633 14.3575 22.2152C14.7445 22.067 15 21.6956 15 21.2812V2.28125C15 1.72897 14.5523 1.28125 14 1.28125H2Z"
      fill="#003061"
      stroke="#003061"
      stroke-width="2"
      stroke-linejoin="round" />
  </g>
</template>
<script>
export default {
  name: 'IconBookmark'
}
</script>
