<template>
  <div
    class="gallery-hover"
    :class="{ [heroSize]: !!heroSize }">
    <section :class="['gallery-hover--hero', 'gallery-hover--hero--' + heroSize, gallery.length < 2?'only-one':'']">
      <div
        v-lazy:backgroundImage="{src:localHero.url,error:imageErrorPlaceholder}"
        class="gallery-hover--img"
        style="min-height: 150px;" />
      <transition name="view-fade-slow">
        <figure
          v-if="selectedImg && selectedImg.url"
          :style="[popStyle]"
          class="gallery-hover--popup">
          <img
            ref="selected"
            :src="selectedImg.url"
            alt="">
        </figure>
      </transition>
      <slot
        name="hero"
        class="gallery-hover--hero-slot" />
    </section>
    <section
      v-if="!hasGalleryHidden && gallery.length > 1"
      v-once
      class="gallery-hover--thumb-wrapper">
      <figure
        v-for="it in 5"
        :key="it"
        :class="['gallery-hover--thumb']"
        @mouseover.stop.prevent="mouseover(gallery[it - 1] && gallery[it - 1].s,$event)"
        @mouseout.stop.prevent="mouseout">
        <HotelImage
          class="gallery-hover--img"
          :image="gallery[it - 1]"
          custom-components="img"
          is-image
          :error-image="imageErrorPlaceholder" />
        <!-- <i
          v-lazy:backgroundImage="{src:gallery[it - 1] && gallery[it - 1].s.url,error:imageErrorPlaceholder}"
          class="gallery-hover--img" /> -->
      </figure>
    </section>
    <!--    <div class="gallery-hover&#45;&#45;thumb-wrapper"-->
    <!--         v-if="!hasGalleryHidden && gallery.length <1" v-once>-->
    <!--      <figure-->
    <!--          v-for="it in 5"-->
    <!--          :key="it"-->
    <!--          :class="['gallery-hover&#45;&#45;thumb']"-->
    <!--      >-->
    <!--        <i class="gallery-hover&#45;&#45;img" v-lazy:backgroundImage="{src:imageErrorPlaceholder}"/>-->
    <!--      </figure>-->
    <!--    </div>-->
    <!--    <div class="gallery-hover&#45;&#45;thumb-wrapper"-->
    <!--         v-if="!hasGalleryHidden && gallery.length > 5" v-once>-->
    <!--      <figure-->
    <!--          v-for="it in 5"-->
    <!--          :key="it"-->
    <!--          :class="['gallery-hover&#45;&#45;thumb']"-->
    <!--          @mouseover.stop.prevent="mouseover(gallery[it + 4] && gallery[it + 4].s)"-->
    <!--          @mouseout.stop.prevent="mouseout">-->
    <!--        <i class="gallery-hover&#45;&#45;img" v-lazy:backgroundImage="gallery[it + 4] && gallery[it + 4].s.url"/>-->
    <!--      </figure>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { findCanLoadedImage } from '@/plugins/image'
import HotelImage from '@/views/hotel/HotelImage.vue'

export default {
  name: 'PropertyGalleryHover',
  components: {
    HotelImage
  },
  props: {
    heroSize: {
      type: String,
      default: ''
    },
    gallery: {
      type: Array,
      default () {
        return [
          {
            s: {
              fileName: 'xx',
              url: 'l'
            }
          }
        ]
      }
    },
    hasGalleryHidden: {
      type: Boolean,
      default: false
    },
    heroImage: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      hoverGalleryVisibility: false,
      selectedImg: {},
      timer: undefined,
      popStyle: {
        height: 0,
        width: 0
      },
      imageErrorPlaceholder: require('@/assets/image-error-placeholder.jpg'),
      localHero: {}
    }
  },
  computed: {
    heroSizeCss () {
      return `gallery-hover--img--${this.heroSize}`
    }
  },
  created () {
    this.initAfterCreated()
  },
  methods: {
    mouseover (val, event) {
      if (!event || event.target.getAttribute('lazy') !== 'loaded') {
        return
      }
      clearTimeout(this.timer)
      this.selectedImg = val
      this.hoverGalleryVisibility = true
      this.$nextTick(() => {
        if (this.selectedImg == null) return
        if (this.$refs.selected.naturalHeight && this.$refs.selected.naturalWidth) {
          this.popStyle = {
            height: `${this.$refs.selected.naturalHeight + 6}px`,
            width: `${this.$refs.selected.naturalWidth + 6}px`
          }
        } else {
          this.popStyle = {}
        }
      })
    },
    mouseout () {
      this.hoverGalleryVisibility = false
      this.timer = setTimeout(() => (this.selectedImg = {}), 300)
    },
    async initAfterCreated () {
      const images = this.gallery.map(item => item.s)
      const hero = this.heroImage
      this.localHero = hero
      const foundImage = await findCanLoadedImage([hero, ...images])
      this.localHero = foundImage || hero
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .gallery-hover {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.large {
      width: 224px;
    }
  }

  .gallery-hover--img {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    background-position: 50% 20%;
    background-size: cover;
    transition: 400ms opacity ease-in;
    border-radius: $bleed 0 0 0;

    .gallery-hover--thumb-wrapper & {
      border-radius: 0 0 0 0;
    }

    &[lazy=loaded], &[lazy=error] {
      opacity: 1;
    }
  }

  .gallery-hover--popup {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    z-index: $_jh_fullscreen-z-index+1;
    display: block;
    box-shadow: 0 5px 5px rgba(10, 10, 10, 0.2), 0 10px 50px 10px rgba(10, 10, 10, 0.2);
    max-height: 200px;
    max-width: 300px;

    & > img {
      width: 100%;
      display: block;
      padding: $bleed /2;
      max-height: 200px;
      max-width: 300px;
    }
  }

  .gallery-hover--hero {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: $bleed 0 0 0;
    flex: 1;

    &.gallery-hover--hero--large {
      width: 224px;
      height: 170px;
      &.only-one {
        height: 222px;
      }
    }

    box-shadow: inset 0 0 40px 5px rgba(10, 10, 10, 0.1);
    display: block;
  }

  .gallery-hover--hero-slot {
    position: absolute;
    bottom: 0;
    padding: $bleed;
    right: 0;
    color: $link;
    background-color: rgba(0, 0, 0, .3);
    // text-shadow: 0 0px 15px rgba(0,0,0,.9);
    // border-radius: $bleed / 2;
    &:active,
    .gallery-hover--hero:active & {
      bottom: -2px;
    }
  }

  .gallery-hover--thumb-wrapper {
    display: flex;

    &:last-child {
      border-radius: 0 0 0 $bleed;
      overflow: hidden;
    }

  }

  .gallery-hover--thumb {
    margin: 2px 2px 0 0;
    box-shadow: inset 0 0 40px 5px rgba(10, 10, 10, 0.1);
    flex: 1;
    height: 50px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0;
    }
  }
</style>
