<template>
  <div
    v-if="isPayLater"
    class="sign-is-pay-later is-size-6 is-block has-text-weight-bold">
    <i class="ap-icon ap-icon-check is-size-7" /> {{ $t('title') }}
  </div>
</template>

<script>
export default {
  name: 'PackageIsPayLater',
  i18n: {
    messages: {
      'en-US': {
        'title': 'Hold & pay later'
      }
    }
  },
  props: {
    isPayLater: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
  .sign-is-pay-later {
    color: #00A0D8;
  }
</style>
