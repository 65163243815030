<template>
  <div
    v-if="covidSafety"
    class="covid-safety">
    <div class="health-insurance--icon" />
    <div class="has-text-base">
      {{ covidSafety }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertyCovidSafetyInfo',
  props: {
    covidSafety: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/bulma-variables';

.covid-safety {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  text-decoration-color: #555;

  & .health-insurance--icon {
    background-image: url('../assets/health-insurance.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 18px;
    margin-right: $bleed;
    width: 18px;
  }
}
</style>
