<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="tooltip"
    placement="right">
    <div
      class="book-mark"
      :class="{bookmarked:localStatus}"
      @click.prevent.stop="toggle">
      <IconBase icon-name="bookmark">
        <IconBookmark v-if="localStatus" />
        <IconBookmarkOutline v-else />
      </IconBase>
    </div>
  </el-tooltip>
</template>

<script>
import IconBookmark from './icons/IconBookmark'
import IconBookmarkOutline from './icons/IconBookmarkOutline'

export default {
  name: 'Bookmark',
  components: {
    IconBookmarkOutline,
    IconBookmark
  },
  props: {
    status: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localStatus: this.status
    }
  },
  computed: {
    tooltip () {
      return this.localStatus ? this.$t('components.Bookmark.bookmarked') : this.$t('components.Bookmark.bookmark')
    }
  },
  watch: {
    status (nV) {
      this.localStatus = this.status
    }
  },
  methods: {
    toggle () {
      this.localStatus = !this.localStatus
      this.$emit('toggled', this.localStatus)
    }
  }

}
</script>

<style lang="scss" scoped>
  @import "../styles/bulma-variables";

  .book-mark {
    display: inline-block;
    color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .bookmarked {
    color: $bookmark-color;
  }
</style>
