<template>
  <a
    v-if="mapLink"
    :href="gmapsLink"
    target="_blank">
    {{ location.address }} <span class="has-text-dark-blue">{{ $t('components.PropertyAddress.title') }}</span>
  </a>
  <span v-else>
    {{ location.address }}
  </span>
</template>

<script>
export default {
  name: 'PropertyAddress',
  props: {
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    },
    mapLink: Boolean,
    propertyCode: String,
    propertyName: String,
    location: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    gmapsLink () {
      if (!this.location.latLng) return '#'
      const baseUrl = 'https://www.google.com/maps/search/'
      const { lat, lng } = this.location.latLng
      const link = `${baseUrl}?api=1&query=${lat},${lng}`
      return link
    }
  }
}
</script>

<style>

</style>
