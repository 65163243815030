<template>
  <div class="item columns is-mobile property-card">
    <div class="column is-narrow col-img">
      <TrustYouRanked
        v-bind="ranking && ranking.badgeData"
        class="is-size-7 ribun"
        layout="vertical" />
      <div class="icon-bookmark">
        <Bookmark
          :status="property.isBookmarked"
          @toggled="handleBookmark" />
      </div>
      <PropertyGalleryHover
        :gallery="propertyGallery"
        :hero-image="heroImage"
        class="property--gallery"
        hero-size="large" />
    </div>
    <div class="column col-content">
      <div class="columns is-gapless property-details">
        <div class="column">
          <StarRatings
            :ratings="property.starRating"
            class="prop-star is-size-7 is-narrow" />
          <h6 class="has-text-primary prop-name has-text-weight-bold m-b is-size-5">
            {{ property.name }}
          </h6>
          <PropertyAddress
            :params="$route.params"
            :query="$route.query"
            :map-link="mapLink"
            :location="property && property.location"
            class="is-size-7 is-block" />
          <span
            v-if="reviewsSummary.location && reviewsSummary.location.text"
            class="is-size-7 is-italic has-text-grey"> ~ {{ reviewsSummary.location.text }}</span>
          <div
            v-if="reviewsSummary.popularWith"
            class="columns"
            style="margin-bottom: 0!important;margin-top: 0!important;">
&nbsp;
            <TrustYouPopularWith
              v-bind="reviewsSummary.popularWith"
              layout="text"
              class="is-size-7 column has-text-dark" />
          </div>
          <PropertyCovidSafetyInfo
            :covid-safety="property.covidSafety"
            class="is-size-7" />
        </div>
        <div class="column is-narrow">
          <TrustYouRating
            :condensed="true"
            v-bind="property.trustYou"
            size="s" />
        </div>
      </div>
      <div class="columns columns-price">
        <div class="column">
          <PackageRefundable :non-refundable="lowestPckg.nonRefundable" />
          <PackagePayAtHotel :pay-at-hotel="payAtHotel" />
          <PackageIsPayLater :is-pay-later="lowestPckg.isPayLater" />
        </div>
        <div class="column is-narrow">
          <div
            v-if="marketRate.value > displayRate.value"
            v-opacity-zero="!marketRate"
            class="has-text-centered property-supplier">
            <a
              v-if="marketRate.supplier"
              :href="marketRate.deeplink_url"
              target="_blank"
              @click.stop.capture="() => {}">
              <div class="is-size-7">
                <img
                  v-show="marketRate.supplier_logo_url"
                  :src="marketRate.supplier_logo_url"
                  class="is-block">
                <span
                  v-show="!marketRate.supplier_logo_url"
                  class="has-text-weight-semibold has-text-dark">
                  {{ marketRate.supplier =='ean' ? $t('components.Property.ean') : marketRate.supplier }}
                </span>
              </div>
              <div class="is-size-6 has-text-weight-bold has-text-base">
                {{ marketRate && marketRate | currencyObj({ factionDigits: 0 }) }}
              </div>
            </a>
          </div>
        </div>
        <div class="column  is-size-6 has-text-black is-narrow  has-text-right">
          <AdjustmentBadge
            :adjustment-pct="lowestPckg.agentInitialAdjustmentPct"
            class="is-size-7" />

          <div
            v-if="lowestPckg.agentInitialAdjustmentPct < -0.01"
            class="has-text-line-through has-text-base is-size-6 has-text-weight-bold is-marginless">
            {{ displayRate | currencyObj({ factionDigits: 0 }) }}
          </div>

          <div class="is-4 subtitle has-text-weight-semibold has-text-primary is-marginless">
            <el-tooltip placement="bottom">
              <div slot="content">
                Note: These rates include {{ getAgentMinMarkupInPct(lowestPckg) }}%<br>
                markup as set up by your account<br>
                admin.
              </div>
              <i
                class="el-icon-info has-text-secondary"
                style="font-size: 0.73em;" />
            </el-tooltip>
            {{ adjustedDisplayRate | currencyObj({ factionDigits: 0 }) }}
          </div>
          <div class="is-size-7 has-text-grey-light has-text-weight-normal">
            {{ nights|nightsAllRoom($store.state.inNightlyAvgRate,$store.state.searchMany.roomCount) }}
          </div>
          <div class="is-size-7 has-text-grey-light has-text-weight-normal">
            Agent earning: {{ lowestPckg.agentMarkupAmount | currencyObj }}
          </div>
          <PriceAudit
            v-if="lowestPckg.raw"
            :raw="lowestPckg.raw" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceAudit from './PriceAudit'
import PropertyAddress from '@/components/PropertyAddress'
import StarRatings from '@/components/StarRatings'
import AdjustmentBadge from '@/components/AdjustmentBadge'
import BadgeOutline from '@/components/BadgeOutline'
import TrustYouRating from '@/components/TrustYou/TrustYouRating'
import TrustYouRanked from '@/components/TrustYouRanked'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import TrustYouBadgeList from '@/components/TrustYouBadgeList'
import PackagesFoodCode from './PackagesFoodCode'
import PackageRefundable from './PackageRefundable'
import PackagePayAtHotel from './PackagePayAtHotel'
import Bookmark from './Bookmark'
import PropertyGalleryHover from './PropertyGalleryHover'
import PackageIsPayLater from '@/components/PackageIsPayLater'
import PropertyCovidSafetyInfo from '@/components/PropertyCovidSafetyInfo'

export default {
  name: 'Property',
  components: {
    PackageIsPayLater,
    PropertyGalleryHover,
    Bookmark,
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PropertyAddress.name]: PropertyAddress,
    [PackagesFoodCode.name]: PackagesFoodCode,
    [BadgeOutline.name]: BadgeOutline,
    [StarRatings.name]: StarRatings,
    [TrustYouRanked.name]: TrustYouRanked,
    [TrustYouRating.name]: TrustYouRating,
    [TrustYouPopularWith.name]: TrustYouPopularWith,
    [TrustYouHotelType.name]: TrustYouHotelType,
    [TrustYouRecommendation.name]: TrustYouRecommendation,
    [TrustYouBadgeList.name]: TrustYouBadgeList,
    [PriceAudit.name]: PriceAudit,
    PackageRefundable,
    PackagePayAtHotel,
    PropertyCovidSafetyInfo
  },
  props: {
    property: Object,
    packages: Array,
    nights: Number,
    layout: String
  },
  data () {
    return {
      // move to vuex store
      //      inNightlyAvgRate: false, // when it's false ,the price is all nights all rooms.  So when all night per room is displayed, do not switch it to false.
      defaultImage: {
        s: {
          fileName: 'xx',
          url: 'l'
        }
      }
    }
  },
  computed: {
    mapLink () {
      return false
      // return this.layout !== 'simple'
    },
    ranking () {
      if (this.reviews.badgeList && this.reviews.badgeList.length) {
        return this.reviews.badgeList.find(({ badgeType }) => badgeType === 'ranking')
      }
      return {}
    },
    reviews () {
      if (this.property.reviews && this.property.reviews.summary) {
        return this.property.reviews
      }
      return {
        summary: {},
        badgeList: [],
        hotelTypeList: []
      }
    },
    lowestPckg () {
      return this.packages[0] || {}
    },
    refundableExist () {
      return !this.packages[0].refundablePackages
    },
    marketRates () {
      return (this.$store.state.inNightlyAvgRate ? this.lowestPckg.unitMarketRates : this.lowestPckg.marketRates) || []
    },
    displayRate () {
      return this.$store.state.inNightlyAvgRate ? this.lowestPckg.agentUnitDisplayRate : this.lowestPckg.agentDisplayRate
    },
    adjustedDisplayRate () {
      return this.$store.state.inNightlyAvgRate ? this.lowestPckg.agentUnitAdjustedDisplayRate : this.lowestPckg.agentAdjustedDisplayRate
    },
    marketRate () {
      return this.marketRates[0] || {}
    },
    propertyGallery () {
      if (this.property.gallery.length && this.property.gallery.length > 1 && this.property.gallery.length < 5) {
        const arr = []
        for (let i = this.property.gallery.length; i < 5; i++) {
          arr.push(this.defaultImage)
        }
        return arr
      }
      return this.property.gallery
    },
    heroImage () {
      const wrongImageUrlRegex = /\/\?wl_app_code|\/undefined\?wl_app_code/
      if (this.property.gallery.length && (!this.property.heroImage || !this.property.heroImage.url || wrongImageUrlRegex.test(this.property.heroImage.url))) {
        const firstValidThumb = this.property.gallery.find(image => image.s && image.s.url && !wrongImageUrlRegex.test(image.s.url))
        if (firstValidThumb) {
          return { ...firstValidThumb.s }
        }
      }
      return this.property.heroImage
    },
    payAtHotel () {
      return this.packages[0].payAtHotel
    },
    reviewsSummary () {
      try {
        return this.reviews.summary || {}
      } catch {
        return {}
      }
    }
  },
  methods: {
    handleBookmark (val) {
      this.$store.dispatch('user/toggleBookmark', {
        flag: val,
        property: {
          ...this.property,
          isBookmarked: true,
          locationQuery: this.$route.params.locationQuery,
          regionId: this.$route.query.regionId
        }
      })
    },
    getAgentMinMarkupInPct (pkg) {
      if (pkg && pkg.agentMinMarkupInPct) return pkg.agentMinMarkupInPct
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .item {
    // padding: ($bleed * 2) $bleed;
    margin: 0;
    // justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .property--gallery {
    height: 100%;
  }

  .col-img {
    padding: 0;
    position: relative;
  }

  .col-content {
    .col-cotnent-wrapper {
      height: 100%;
      flex-direction: column;
    }
  }

  .col-pckg-details {
    display: flex;
    align-items: flex-end;
  }

  .ribun {
    position: absolute;
    z-index: 1;
    margin: $bleed*2 $bleed $bleed -#{$bleed};
  }

  .icon-bookmark {
    position: absolute;
    z-index: 1;
    top: $bleed*2;
    left: 198px;
    color: transparent !important;
  }

  .item-gap {
    margin-top: $bleed * 25;
  }

  .property-image {
    width: 200px;
    min-height: 200px;
    height: 100%;
    background-size: cover;
    background-position: center center;
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  .property-supplier {
    padding-right: 0px;
  }

  .property-card {
    box-shadow: $card-shadow-simple;
    border-radius: $bleed;
    transition: box-shadow 80ms $selected-transition;
    height: 100%;
    &:hover {
      box-shadow: $selected-box-shadow;
    }
  }

  .columns-price {
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    .column {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }

  .col-content {
    display: flex;
    flex-direction: column;
  }

  .property-details {
    padding-bottom: $bleed*2;
    margin-bottom: auto !important;
  }

</style>
