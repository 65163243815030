<template>
  <div
    v-if="!nonRefundable"
    class="is-size-6 is-block has-text-success has-text-weight-bold m-t-2x">
    <i class="ap-icon ap-icon-check is-size-7" /> {{ $t('Refundable') }}
  </div>
</template>

<script>
export default {
  name: 'PackageRefundable',
  i18n: {
    messages: {
      'en-US': {
        'Refundable': 'Refundable'
      }
    }
  },
  props: {
    nonRefundable: {
      type: Boolean,
      default: true
    }
  }
}
</script>
