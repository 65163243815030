<template>
  <div
    v-if="payAtHotel"
    class="is-size-6 is-block has-text-success has-text-weight-bold m-t-2x">
    <i class="ap-icon ap-icon-check is-size-7" /> {{ $t('PayAtHotel') }}
  </div>
</template>

<script>
export default {
  name: 'PackagePayAtHotel',
  i18n: {
    messages: {
      'en-US': {
        'PayAtHotel': 'Pay at Hotel'
      }
    }
  },
  props: {
    payAtHotel: {
      type: Boolean,
      default: true
    }
  }
}
</script>
